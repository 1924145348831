/**
 * 
 * @param {String} str 
 * @returns Boolean 
 * Allowed signed and float numbers or empty string
 */
export function isNumber(str) {
    str = str.toString()
    const NUMBER_REGEX = /^-?\d*\.?\d*$/
    return NUMBER_REGEX.test(str)
}


export const convertToWei = (t, decimal=18) => t * (10 ** decimal)
export const WeiToNumber = (t, decimal=18) => t / (10 ** decimal)