import { useWalletContext } from 'hooks/walletContext'
import React, { useState ,useEffect} from 'react'
import Button from 'shared/Button/Button'
import { alertError, alertSuccess } from 'utils/alerts'
import Swal from 'sweetalert2';
import info from "images/info.svg"
import Tooltip from 'components/tooltip'

const TOOLTIPTEXT = `This changes the owner of liquidity address`
const ChangeLiquidityAddress = ({isOwner=false}) => {
    const [newLiquidity, setNewLiquidity] = useState(null)
    const { getContractInstance,getConnectAddress}= useWalletContext()
    
    const changeLiquidity = async () => {
        if(!newLiquidity){
            alertError({msg:"Please Add Valid Token Address"})
            return
        }
        try{
            const tb = await getContractInstance();
            const newLiquidityFunc = await tb.changeLiquidityAddress(newLiquidity);
            const result=await newLiquidityFunc.wait();
            // console.log("newLiquidity ::", newLiquidity);
            console.log("result ::", result);
            alertSuccess({msg: "Liquidity address changed Successfully"});
            setNewLiquidity("")
        }catch(err){
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Invalid/Unauthorized Address"
            alertError({ msg });
        }
    }

    const confirmLiquidAddr = (e) => {
        if(!newLiquidity){
            alertError({ msg: "Please Add Valid Owner Address" })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to change Liquidity  Address to ${newLiquidity}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                changeLiquidity()
            }
          })
    }

    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])

    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
       <div className='text-xl font-medium whitespace-nowrap '>Change Liquidity Address<Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>

            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">New Liquidity Address <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                    <input type="text" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='New Address '
                        value={newLiquidity}
                        onChange={(e) =>setNewLiquidity(e.target.value)} 
                        
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : confirmLiquidAddr()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem] `}>Add New Liquidity</Button>
            </div>
        </div>
    )
}

export default ChangeLiquidityAddress