
import FiatPaymentTable from "components/FiatPaymentTable/FiatPaymentTable";
import DashboardHeader from "components/Header/DashboardHeader";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";


const Page = () => {

    return (

        <div className="nc-PageHome relative overflow-hidden nc-fill-container p-4">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="Fiat Payment" />

            <div>
                <FiatPaymentTable/>
            </div>
        </div>
    );
}

export default Page;
