import React, { useState, useEffect } from 'react'
import { useWalletContext } from 'hooks/walletContext';
import Button from 'shared/Button/Button'
import Swal from 'sweetalert2';
import { alertSuccess, alertError } from 'utils/alerts';
import info from "images/info.svg"
import Tooltip from 'components/tooltip'
const TOOLTIPTEXT = `This feature accept the new token to contribute on contract`

const AddToWhiteListToken = ({isOwner=false}) => {

    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [tokenAddress, setTokenAddress] = useState("")
    const [loading, setloading] = useState(false)
    const [isActive, setisActive] = useState(null)

    const addToWhiteListToken = async () => {
        try {
            setloading(true)
            if (!tokenAddress) {
                alertError({ msg: "Please Add Valid Token Address" })
                return
            }
            if(isActive === null){
                alertError({msg:"Please select Active Value"})
                return
            }
            const tb = await getContractInstance();
            const whitelistTokenRes = await tb.whitelistToken(tokenAddress, Boolean(isActive));
            await whitelistTokenRes.wait();
            alertSuccess({msg:"Token Added Successfully"});
            setTokenAddress("");
        }
        catch (err) {
            console.log("error",err);
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
            alertError({ msg })
        } finally {
            setloading(false)
        }
    }


    const confirmWL = (e) => {
        if (!tokenAddress) {
            alertError({ msg: "Please Add Valid Token Address" })
            return
        }
        if(isActive === null){
            alertError({msg:"Please select Active Value"})
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to Add Token with Address ${tokenAddress}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                addToWhiteListToken()
            }
          })
    }


    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])
    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full ">
         <div className='text-xl font-medium whitespace-nowrap '>Add To WhiteList Token<Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>

            <div className="gap-3 sm:flex whitespace-nowrap place-items-center mt-4">
                <div className="sm:flex place-items-center sm:w-1/4 sm:justify-end"><label className="font-medium">Token Address <span className='px-3'>{":"}</span></label></div>
                <div className="h-10 flex place-items-center w-full"><input type="text" className='text-sm rounded-md bg-website-500  w-full' placeholder="Token Address" value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} /></div>
            </div>
            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">Enable <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                <select name="isActive" id="isActive"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Change Status'
                        value={isActive}
                        onChange={(e) => setisActive(e.target.value)}
                    >
                        <option disa>none</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button loading={loading} onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : confirmWL()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 `}>Add To WhiteList Token</Button>
            </div>
        </div>
    )
}

export default AddToWhiteListToken;