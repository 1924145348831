import React, { useState, useEffect } from 'react'
import { useWalletContext } from 'hooks/walletContext';
import Button from 'shared/Button/Button'
import { ethers } from "ethers";
import usdIcon from 'images/icons/USD.svg'
import { alertSuccess ,alertError } from 'utils/alerts';

const convertToWei = (t) => t * (10 ** 18)
const Data = [
    {
        "username": "John Mathew",
        "whereDidHeBuy": "fiat",
        "holderWalletAddress": "0x1ebE0506DeDCa93470Ec3ae0cE6642F12C28Ca42",
        "amount": 50,
    },
    {
        "username": "Oliver William",
        "whereDidHeBuy": "fiat",
        "holderWalletAddress": "0x1ebE0506DeDCa93470Ec3adecE6642F12C28Ca42",
        "amount": 50,
    },
    {
        "username": "Lucas Mark",
        "whereDidHeBuy": "fiat",
        "holderWalletAddress": "0x1ebE0506DeDCa003470Ec3ae0cE6642F12C28Ca42",
        "amount": 50,
    },
    {
        "username": "Tony Stark",
        "whereDidHeBuy": "fiat",
        "holderWalletAddress": "0x1ebE0506DeDCa93470Ec3ae0c92642F12C28Ca42",
        "amount": 50,
    },
    {
        "username": "Elijah John",
        "whereDidHeBuy": "fiat",
        "holderWalletAddress": "0x1ebE0506DeDCa93470Ec3ae0cE6642F12C28Cac7",
        "amount": 50,
    },
]

const ContributionTable = () => {
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    const manualPay = async (data) => {
        try {
            console.log("Contribution data :", data)
    
            if (!data.holderWalletAddress || data.amount <= 0) return
            data.whereDidHeBuy ??= 'fiat';
            console.log();
            let weiTokens = convertToWei(Number(data.amount))
            console.log("amountIn ::", weiTokens);

            const tb = await getContractInstance()
            const amountIn = ethers.utils.parseUnits(weiTokens.toString(), "wei")
            console.log("amountIn ::", amountIn);
            const manualPay = await tb.manualBuy(true, data.whereDidHeBuy, data.holderWalletAddress, amountIn).call();
            await manualPay.wait()
            console.log("manualPay ::", manualPay);
            alertSuccess({msg: `Amount Added to ${data.holderWalletAddress}`})
        } catch (error) {
            console.log("error",error.message);
        }

    }

    const [contractOwner, setcontractOwner] = useState("");
    const [currentAddr, setcurrentAddr] = useState("")
    const getContractOwner = async (second) => {
        const curAddr = await getConnectAddress();
        const tb = await getContractInstance();
        const owner = await tb.owner();
        console.log(" curAddr,owner ", curAddr, owner);
        setcontractOwner(owner.toLocaleLowerCase());
        setcurrentAddr(curAddr.toLocaleLowerCase())
    }

    // if(currentAddr === contractOwner) setisManualBuyDisable(true)
    // else setisManualBuyDisable(false)

    useEffect(() => {
        getContractOwner();
    }, [])
    return (
        <div className=" p-5 bg-[#161616] mt-6 rounded-lg" >
            <div className=" flex justify-between">
                <div>
                    <h1 className="text-base text-white">Contribution</h1>
                    <h1 className="text-xs text-[#949494]">You can see all your contribution here</h1>
                </div>
                <div className="text-primary-900 text-xs">
                    View All
                </div>
            </div>
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-x-auto">
                            <table class="min-w-full table-auto">
                                <thead class="border-b">
                                    <tr className='h-[54px] 1px solid #303030' >
                                        <th className='text-left text-md md:w-[15%]'> User Name</th>
                                        <th className='text-left text-md md:w-[35%]'> Wallet Addess</th>
                                        <th className='text-left text-md md:w-[25%]'>Amount</th>
                                        <th className='text-left text-md md:w-[25%] '>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {true ? Data.map((data, index) => {
                                        return <tr key={index} className='h-[54px]' style={{ borderTop: '1px solid #303030', borderBottom: '1px solid #303030' }}>
                                            <td className='text-[12px] text-start md:w-[15%]'>{data.username}</td>
                                            <td className='text-[12px] text-start md:w-[35%]'>{data.holderWalletAddress}</td>
                                            <td className='text-[12px] text-start md:w-[25%] '>
                                                <span className="w-[18px] h-[18px] mr-1" >
                                                    <img className="inline mt-[-2px]" src={usdIcon} />
                                                </span>
                                                {data.amount}
                                            </td>
                                            <td className='text-[12px] text-start md:w-[25%] rounded-2xl '>
                                                {/* <button style={{ border: '1px solid #1EDF65', background: " #1EDF6529 0% 0% no-repeat padding-box" }} className="rounded-2xl bg-[] h-[22px] w-[77px] text-[12px] text-[#1EDF65] text-center">
                                                    Execute
                                                </button> */}
                                                <Button
                                                    onClick={() => {
                                                        setisManualBuyDisable(contractOwner === data.holderWalletAddress);
                                                        isManualBuyDisable ? manualPay(data) : alertError({msg: "Please connect with Owner's Wallet"})
                                                    }
                                                    }
                                                    border="rounded-md"
                                                    sizeClass="px-5 py-1"
                                                    className={`bg-primary-900 text-md  text-website-220 `}>
                                                    Execute
                                                </Button>
                                            </td>
                                        </tr>;
                                    }) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContributionTable