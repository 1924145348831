import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MenuBar from 'shared/MenuBar/MenuBar';

export default function Sidebar({ show = true, active }) {
  const { sidebarActiveMenuId } = useSelector(state => state.menu)
  // ${show ? "block" : "hidden"}
  return (
    <div
      className={`hidden xl:block bg-website-220 h-[100] w-2/12 `}
    >
      <div className="logo-head mt-8">
        <h1 className="text-2xl whitespace-nowrap cursor-pointer font-strasua text-primary-900 uppercase text-center">
          BEC Tokens
        </h1>
      </div>
      <div className="bg-website-480 h-[1px] w-[80%] m-auto mt-4 mb-6"></div>
      <NavLink activeId="dashboard" to="/dashboard">
        <span className="w-5 h-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g
              id="Group_2514"
              data-name="Group 2514"
              transform="translate(-26.974 -100.083)"
            >
              <g
                id="Group_2512"
                data-name="Group 2512"
                transform="translate(26.974 99.701)"
              >
                <rect
                  id="Rectangle_166"
                  data-name="Rectangle 166"
                  width="7"
                  height="9"
                  rx="1"
                  transform="translate(0 0.383)"
                  fill={
                    sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"
                  }
                />
                <rect
                  id="Rectangle_168"
                  data-name="Rectangle 168"
                  width="7"
                  height="5"
                  rx="1"
                  transform="translate(0 11.383)"
                  fill={
                    sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"
                  }
                />
              </g>
              <g
                id="Group_2513"
                data-name="Group 2513"
                transform="translate(42.974 115.466) rotate(180)"
              >
                <rect
                  id="Rectangle_166-2"
                  data-name="Rectangle 166"
                  width="7"
                  height="9"
                  rx="1"
                  transform="translate(0 -0.617)"
                  fill={
                    sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"
                  }
                />
                <rect
                  id="Rectangle_168-2"
                  data-name="Rectangle 168"
                  width="7"
                  height="5"
                  rx="1"
                  transform="translate(0 10.383)"
                  fill={
                    sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"
                  }
                />
              </g>
            </g>
          </svg>
        </span>
        <span className="ml-4">Dashboard</span>
      </NavLink>
      {/* <NavLink activeId="contribution" to="/dashboard/contribution">
        <span className="w-5 h-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g
              id="Group_2514"
              data-name="Group 2514"
              transform="translate(-26.974 -100.083)"
            >
              <g
                id="Group_2512"
                data-name="Group 2512"
                transform="translate(26.974 99.701)"
              >
                <rect
                  id="Rectangle_166"
                  data-name="Rectangle 166"
                  width="7"
                  height="9"
                  rx="1"
                  transform="translate(0 0.383)"
                  fill={
                    sidebarActiveMenuId == "contribution" ? "#e5bf00" : "#737373"
                  }
                />
                <rect
                  id="Rectangle_168"
                  data-name="Rectangle 168"
                  width="7"
                  height="5"
                  rx="1"
                  transform="translate(0 11.383)"
                  fill={
                    sidebarActiveMenuId == "contribution" ? "#e5bf00" : "#737373"
                  }
                />
              </g>
              <g
                id="Group_2513"
                data-name="Group 2513"
                transform="translate(42.974 115.466) rotate(180)"
              >
                <rect
                  id="Rectangle_166-2"
                  data-name="Rectangle 166"
                  width="7"
                  height="9"
                  rx="1"
                  transform="translate(0 -0.617)"
                  fill={
                    sidebarActiveMenuId == "contribution" ? "#e5bf00" : "#737373"
                  }
                />
                <rect
                  id="Rectangle_168-2"
                  data-name="Rectangle 168"
                  width="7"
                  height="5"
                  rx="1"
                  transform="translate(0 10.383)"
                  fill={
                    sidebarActiveMenuId == "contribution" ? "#e5bf00" : "#737373"
                  }
                />
              </g>
            </g>
          </svg>
        </span>
        <span className="ml-4">Contribution</span>
      </NavLink> */}
      {/* <NavLink activeId="fiat-payment" to="/dashboard/fiat-payment">
        <span className="w-5 h-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.958"
            height="19.126"
            viewBox="0 0 9.958 19.126"
          >
            <path
              id="Icon_metro-dollar2"
              data-name="Icon metro-dollar2"
              d="M19.915,14.851a4.06,4.06,0,0,1-1.062,2.812,4.583,4.583,0,0,1-2.759,1.457v1.868a.329.329,0,0,1-.342.342H14.312a.346.346,0,0,1-.342-.342V19.12a6.728,6.728,0,0,1-1.361-.331,6.554,6.554,0,0,1-1.873-.987,6.082,6.082,0,0,1-.5-.4q-.133-.128-.187-.192a.324.324,0,0,1-.021-.438l1.1-1.441a.332.332,0,0,1,.245-.128.264.264,0,0,1,.256.1l.021.021a5.573,5.573,0,0,0,2.594,1.334,3.728,3.728,0,0,0,.79.085,2.588,2.588,0,0,0,1.521-.459,1.5,1.5,0,0,0,.656-1.3,1.082,1.082,0,0,0-.16-.566,2,2,0,0,0-.358-.448,2.929,2.929,0,0,0-.624-.4q-.427-.219-.7-.342t-.854-.347q-.416-.171-.656-.267t-.656-.283q-.416-.187-.667-.331t-.6-.379a3.758,3.758,0,0,1-.571-.454,6.652,6.652,0,0,1-.464-.523,2.611,2.611,0,0,1-.379-.619,4.28,4.28,0,0,1-.224-.71,3.7,3.7,0,0,1-.091-.832A3.649,3.649,0,0,1,11.249,5.9a4.884,4.884,0,0,1,2.722-1.43V2.545a.346.346,0,0,1,.342-.342h1.441a.329.329,0,0,1,.342.342V4.423a5.937,5.937,0,0,1,1.179.245,6.838,6.838,0,0,1,.929.358,5.142,5.142,0,0,1,.678.4q.32.224.416.31t.16.149a.309.309,0,0,1,.053.406l-.865,1.558a.291.291,0,0,1-.245.171.34.34,0,0,1-.288-.075q-.032-.032-.155-.128t-.416-.283a5.507,5.507,0,0,0-.624-.342,4.767,4.767,0,0,0-.8-.277,3.561,3.561,0,0,0-.913-.123,2.774,2.774,0,0,0-1.654.459,1.435,1.435,0,0,0-.55,1.7,1.2,1.2,0,0,0,.315.443,4.7,4.7,0,0,0,.422.352,3.759,3.759,0,0,0,.6.331q.4.187.646.288t.747.293q.566.213.865.336t.811.374a6.448,6.448,0,0,1,.806.454,6.814,6.814,0,0,1,.662.534,2.763,2.763,0,0,1,.566.678,3.868,3.868,0,0,1,.336.816,3.521,3.521,0,0,1,.139,1Z"
              transform="translate(-9.957 -2.203)"
              fill={
                sidebarActiveMenuId == "fiat-payment" ? "#e5bf00" : "#737373"
              }
            />
          </svg>
        </span>
        <span className="ml-4">Fiat Payment</span>
      </NavLink>
      <NavLink activeId="crypto-payment" to="/dashboard/crypto-payment">
        <span className="w-5 h-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.006"
            height="18"
            viewBox="0 0 13.006 18"
          >
            <path
              id="Icon_metro-bitcoin"
              data-name="Icon metro-bitcoin"
              d="M19.966,9.945a2.564,2.564,0,0,1-1.417,2.791,3.249,3.249,0,0,1,1.893,1.114,3.206,3.206,0,0,1,.487,2.315,4.09,4.09,0,0,1-.352,1.352,2.985,2.985,0,0,1-.7.963,3.551,3.551,0,0,1-1.049.633,6.393,6.393,0,0,1-1.314.373,13.034,13.034,0,0,1-1.574.162v2.758H14.276V19.692q-.865,0-1.32-.011v2.726H11.291V19.648q-.195,0-.584-.005t-.595-.005H7.948l.335-1.98h1.2a.578.578,0,0,0,.627-.552V12.758h.173a1.1,1.1,0,0,0-.173-.011v-3.1a.859.859,0,0,0-.963-.736h-1.2V7.133l2.293.011q.692,0,1.049-.011V4.407h1.666V7.079q.887-.022,1.32-.022V4.407h1.666V7.133a9.651,9.651,0,0,1,1.514.243,4.919,4.919,0,0,1,1.222.487,2.478,2.478,0,0,1,.892.844,2.76,2.76,0,0,1,.395,1.239Zm-2.326,5.9a1.443,1.443,0,0,0-.162-.692,1.622,1.622,0,0,0-.4-.5,2.054,2.054,0,0,0-.622-.33,5.252,5.252,0,0,0-.709-.2,6.4,6.4,0,0,0-.8-.1q-.476-.032-.746-.032T13.5,14q-.427.011-.514.011v3.656l.4.005q.314.005.519.005t.573-.016q.368-.016.633-.043t.617-.092a4.14,4.14,0,0,0,.6-.151,3.676,3.676,0,0,0,.514-.227,1.5,1.5,0,0,0,.427-.325,1.529,1.529,0,0,0,.265-.433,1.434,1.434,0,0,0,.1-.552Zm-.768-5.149a1.42,1.42,0,0,0-.135-.633,1.507,1.507,0,0,0-.33-.454,1.617,1.617,0,0,0-.519-.3,3.718,3.718,0,0,0-.595-.178,5.956,5.956,0,0,0-.665-.087Q14.233,9,14,9.01l-.584.011-.427.005v3.321l.373.005q.319.005.5,0t.541-.022A4.575,4.575,0,0,0,15,12.271q.238-.043.557-.119a1.834,1.834,0,0,0,.525-.2,2.97,2.97,0,0,0,.4-.292,1.041,1.041,0,0,0,.292-.416,1.5,1.5,0,0,0,.1-.552Z"
              transform="translate(-7.948 -4.407)"
              fill={
                sidebarActiveMenuId == "crypto-payment" ? "#e5bf00" : "#737373"
              }
            />
          </svg>
        </span>
        <span className="ml-4">Crypto Payment</span>
      </NavLink>
      <NavLink activeId="transaction" to="/dashboard/transaction">
        <span className="w-5 h-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g
              id="Group_2516"
              data-name="Group 2516"
              transform="translate(-31.671 -250)"
            >
              <g
                id="Icon_feather-arrow-down-right"
                data-name="Icon feather-arrow-down-right"
                transform="translate(33.671 265.682) rotate(-45)"
              >
                <path
                  id="Path_2326"
                  data-name="Path 2326"
                  d="M0,0,8.935,8.935"
                  transform="translate(0 0)"
                  fill="none"
                  stroke={
                    sidebarActiveMenuId == "transaction" ? "#e5bf00" : "#737373"
                  }
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <path
                  id="Path_2327"
                  data-name="Path 2327"
                  d="M4,0V4H0"
                  transform="translate(4.938 4.938)"
                  fill="none"
                  stroke={
                    sidebarActiveMenuId == "transaction" ? "#e5bf00" : "#737373"
                  }
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
              <g
                id="Icon_feather-arrow-down-right-2"
                data-name="Icon feather-arrow-down-right"
                transform="translate(46.307 258.318) rotate(135)"
              >
                <path
                  id="Path_2326-2"
                  data-name="Path 2326"
                  d="M0,0,8.935,8.935"
                  transform="translate(0 0)"
                  fill="none"
                  stroke={
                    sidebarActiveMenuId == "transaction" ? "#e5bf00" : "#737373"
                  }
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <path
                  id="Path_2327-2"
                  data-name="Path 2327"
                  d="M4,0V4H0"
                  transform="translate(4.938 4.938)"
                  fill="none"
                  stroke={
                    sidebarActiveMenuId == "transaction" ? "#e5bf00" : "#737373"
                  }
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </g>
          </svg>
        </span>
        <span className="ml-4">Transaction</span>
      </NavLink>
      {/* <NavLink activeId="kyc-application" to="/dashboard/kyc-application">
          <span className='w-5 h-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="Icon_material-verified-user" data-name="Icon material-verified-user" d="M11.324,1.5,4.5,4.533V9.082a9.436,9.436,0,0,0,6.824,9.1,9.436,9.436,0,0,0,6.824-9.1V4.533ZM9.807,13.631,6.775,10.6,7.844,9.529l1.964,1.956,5-5,1.069,1.077Z" transform="translate(-4.5 -1.5)" fill={sidebarActiveMenuId=="kyc-application"?"#e5bf00":"#737373"}/>
            </svg>
          </span>
          <span className='ml-4'>KYC Application</span>
      </NavLink> */}
    </div>
  );
}

const NavLink = ({ children, to }) => {
  return <div className='px-7 py-4 text-base'>
    <Link className='flex flex-row items-center' to={to}>
      {children}
    </Link>
  </div>
}
