import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import swal from 'sweetalert';


export const alertSuccess = ({ msg = "", options = {} }) => {

    return swal("Success!", msg, "success");
    return toast.success(msg, {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#ffffff"
    });
}
export const alertError = ({ msg = "", options = {} }) => {

    return swal("Error!", msg, "error");
    return toast.error(msg, {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#ffffff"
    });

}
export const alertWarning = ({ msg = "", options = {} }) => {

    return swal("Warning!", msg, "warning");
    return toast.warn(msg, {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#ffffff"
    });
}

export const alertInfo = ({ msg = "", options = {} }) => {
    return swal("Info!", msg, "info");
    return toast.info(msg, options);
}

export const showCustom = ({ msg = "", options = {} }) => {
    return toast(msg, options);
}
