import React, {Fragment, useState, useEffect} from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { Redirect } from "react-router-dom";
import { userSlice } from "../../app/user/user";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";





const MainNav2Logged = () => {
  const {user} = useSelector(state=>state)
  const dispatch = useDispatch()
  const history = useHistory()
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)

  const toggleModal = () => {
    console.log("here")
    let t = !isShow;
    setIsShow(!isShow)
  }

  const connectWallet = async () => {
    let userObj = {
      "_id": "637e1a943fc4bc1e8019a9cd",
      "userId": "637c6dba6994e83444370324",
      "orgId": "637b6ca71027d500dca99f40",
      "active": true,
      "role": 1,
      "invitedBy": "6371e958118c693848594082",
      "createdAt": "2022-11-23T13:05:24.274Z",
      "updatedAt": "2022-11-23T13:05:24.274Z",
      "seqId": 56,
      "__v": 0,
      "userObj": {
        "_id": "637c6dba6994e83444370324",
        "fName": "Arpan",
        "lName": "Patra",
        "role": "user",
        "country": "",
        "phoneNumber": "",
        "profilePic": "https://stardust-asset-qa.s3.ap-southeast-1.amazonaws.com/uploads/1671016122596Image-16.png",
        "coverImage": "https://wallpaperaccess.com/full/1348033.jpg",
        "bio": "",
        "isEmailVerified": false,
        "kycStatus": false,
        "wallet": {
          "default": "0x00d53c0c962Ddfe4940FfC750Bf9d4dc8488C9B9",
          "metamask": "0x00d53c0c962Ddfe4940FfC750Bf9d4dc8488C9B9"
        },
        "active": true,
        "orgId": null,
        "brandId": null,
        "profileStatus": "activated",
        "gender": 0,
        "isPrivate": false,
        "status": "pending",
        "isAgent": false,
        "isFiat": false,
        "facebookUrl": "",
        "twitterUrl": "",
        "instagramUrl": "",
        "discordId": "",
        "username": "arpan",
        "email": "apatra@sdlccorp.com",
        "password": "$2a$08$gg9YCeVmUZQ63JQe3j2pjOAsiT0SUXWvJGvO.bCXjV7c9KWm4V70y",
        "createdAt": "2022-11-22T06:35:38.575Z",
        "updatedAt": "2022-12-14T11:08:47.230Z",
        "seqId": 397,
        "__v": 0,
        "referalCode": "arpan"
      },
    }
    try {
     
      const accounts = null
      if (accounts) {
        dispatch(userSlice.actions.changeCurrentUser({ userObj, id: accounts }));
        // setTimeout(function() {
        // }, 700);
        history.replace("/dashboard")
        setAccount(accounts[0]);
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className={`nc-MainNav2Logged relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        {/* <ConnectWalletToggel isShow={isShow} toggleModal={toggleModal} connectWallet={connectWallet} /> */}
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
          {/*   <Navigation /> */}
            {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div> */}
            {/* <div className="flex">
              <NotifyDropdown />
            </div> */}
            <div></div>
            {/* {
              user.id
              ? <AvatarDropdown /> 
              : <ButtonPrimary href={"/login"} fontSize="test-sm font-medium" sizeClass="px-11 py-2">Login</ButtonPrimary>
            } */}
            {
              user.id ? <Link to={"/dashboard"}>
                {/* <ButtonPrimary fontSize="test-sm font-medium" sizeClass="px-11 py-2">Dashboard</ButtonPrimary> */}
                </Link>
                :""
              // : <ButtonPrimary onClick={() => toggleModal()}  fontSize="test-sm font-medium" sizeClass="px-11 py-2">Connect Wallet</ButtonPrimary>
            }
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {/* <NotifyDropdown /> */}
            {
              user.id
              ? <AvatarDropdown />
              : null
            }
            {/* <AvatarDropdown /> */}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export const truncateAddress = (address, connectWallet) => {
  if (!address) {

    return (

      <ButtonPrimary onClick={() => connectWallet()}  fontSize="test-sm font-medium" sizeClass="px-11 py-2">Connect Wallet</ButtonPrimary>
    )
  }
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const ConnectWalletToggel = ({ isShow = false, toggleModal, connectWallet }) => {
  function closeModal() {
    toggleModal()
  }

  return (
    <>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl flex justify-center text-left align-middle shadow-xl transition-all">
                  <ConnectWalletModal onClick={() => closeModal()} connectWallet={connectWallet} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default MainNav2Logged;
