import { useWalletContext } from 'hooks/walletContext'
import React, { useState, useEffect } from 'react'
import Button from 'shared/Button/Button'
import { alertError, alertSuccess } from 'utils/alerts'
import info from "images/info.svg"
import Swal from 'sweetalert2';
import Tooltip from 'components/tooltip'
const TOOLTIPTEXT = `Withdraw the amount from whitelisted tokens`;

const RemoveTokens = ({ isOwner = false }) => {
    const [address, setAddress] = useState(null)
    const [amount, setAmount] = useState(null)
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const removeTokens = async () => {
        // console.log("tokenAddress :", newLiquidity)
        if (!address) {
            alert("Please Add Valid Whitelisted  Address")
            return;
        }
        if (!amount) {
            alert("Please Add Valid Amount")
            return;
        }
        try {
            const tb = await getContractInstance();
            const removeTokensFunc = await tb.removeTokens(address, amount);
            const result = await removeTokensFunc.wait();
            alertSuccess({ msg: "Liquidity address changed Successfully" });
            setAddress(null)
            setAmount(null)
        } catch (err) {
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Unauthorized Access"
            alertError({ msg });
        }
    }

    const confirmWithDraw = (e) => {
        if (!address) {
            alert("Please Add Valid Whitelisted  Address")
            return;
        }
        if (!amount) {
            alert("Please Add Valid Amount")
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to withdraw ${amount} from Token Address ${address}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                removeTokens()
            }
        })
    }

    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)

    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])

    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
            <div className='text-xl font-medium whitespace-nowrap '>Remove Tokens <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>
            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4"> Token Address <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                    <input type="text" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
            </div>
            <div className="  gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">Amount <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                    <input type="number" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Amount'
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button onClick={() => !isManualBuyDisable ? alertError({ msg: "Please connect with Owner's Wallet" }) : confirmWithDraw()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Remove</Button>
            </div>
        </div>
    )
}

export default RemoveTokens