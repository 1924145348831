import { menuSlice } from 'app/menu/menu'
import ContributionTable from 'components/ContributionTable/ContributionTable'
import DashboardHeader from 'components/Header/DashboardHeader'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

const Contribution = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        /* Set Active Menu */
        dispatch(menuSlice.actions.setSidebarActiveMenu('contribution'));
    }, [])
    return (
        <div className="nc-PageHome relative overflow-hidden nc-fill-container p-4">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="Dashboard" />
            <ContributionTable />
        </div>
    )
}

export default Contribution