import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/utility.png";
import logoLightImg from "images/utility.png";

const Logo = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      <h1 className="text-2xl font-strasua text-primary-900 uppercase">BEC Token</h1>
    </Link>
  );
};

export default Logo;
