import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeCurrentUser: (
      state,
      action
    ) => {
      return {
        ...action.payload,
      };
    },
    changeStateUser: (
      state,
      action
    ) => {
      return {
        ...state,
        state: action.payload,
      };
    },
    removeUser: () => {
      return {};
    },
    //
    addNewIdListAudio: (state, action) => {
      return {
        ...state,
        listIdAudio: [...(state.listIdAudio || []), action.payload],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCurrentUser,
  changeStateUser,
  removeUser,
  addNewIdListAudio,
} = userSlice.actions;

export const selectCurrentUser = (state) =>
  state.user;

export default userSlice.reducer;
