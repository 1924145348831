import { menuSlice } from "app/menu/menu";
import DashboardHeader from "components/Header/DashboardHeader";
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import iconone from "../../images/icons/1200px-Bitcoin.svg-1.png"
import icontwo from "../../images/icons/5F33E3F751873296-1.png"


const CryptoPaymentTable = () => {

    const dispatch = useDispatch()

  useEffect(() => {
      /* Set Active Menu */
      dispatch(menuSlice.actions.setSidebarActiveMenu('crypto-payment'));
  }, [])
    
    
    const Data = [
        {
            "username":"John Mathew",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":icontwo
            
        },
      
        {
            "username":"Oliver William",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":iconone
            
        },
      
        {
            "username":"Lucas Mark",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":iconone
            
        }, 
         {
            "username":"Tony Stark",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":icontwo
            
        },
         {
            "username":"Benjamin",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":icontwo
            
        },
         {
            "username":"Elijah John",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Susscess",
            "icon":icontwo
            
        },
         {
            "username":"Tony Stark",
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token" : "18,750",
            "status":"Fern",
            "icon":iconone
            
        }
      
    ]


  return (
    <div className="nc-PageHome relative overflow-hidden nc-fill-container ">

    <div className=" p-5 bg-[#161616] mt-6 rounded-lg" >
           <div className=" flex justify-between">
            <div>
            <h1 className="text-base text-white">Transaction</h1>
            <h1 className="text-xs text-[#949494]">You can see all your transaction here</h1>
            </div>
            <div className="text-primary-900 text-xs">
            View All
            </div>
           </div>
            <div class="flex flex-col">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-x-auto">
                            <table class="min-w-full">
                            <thead class="border-b">
                            <tr className='h-[54px] 1px solid #303030' >
                            <th className='text-left text-[12px] md:w-[25%]'> User Name</th>
                                <th className='text-left text-[12px] md:w-[25%]'>Date</th>
                                <th className='text-left text-[12px] md:w-[25%]'>Amount</th>
                                <th className='text-left text-[12px]  md:w-[25%] '>BEC Token Receive</th>
                                <th className='text-left text-[12px] '>Status</th>
                                
                            </tr>
                            </thead>
                            <tbody>
                            {true ? Data.map((data, index) => {
                                return <tr key={index} className='h-[54px]' style={{borderTop:'1px solid #303030',borderBottom:'1px solid #303030'}}>
                                    <td className='text-[12px] text-start md:w-[25%]'>{data.username}</td>
                                    <td  className='text-[12px] text-start md:w-[25%]'>{data.date}</td>
                                    <td  className='text-[12px] text-start md:w-[25%] '><span className="w-[18px] h-[18px] mr-1" ><img className="inline mt-[-2px]" src={data.icon} /></span>{data.amount}</td>
                                    <td  className='text-[12px] text-start   md:w-[25%]' >{data.token}</td>
                                    <td  className='text-[12px] text-start rounded-2xl '><button style={{border:'1px solid #1EDF65' ,background:" #1EDF6529 0% 0% no-repeat padding-box"}} className="rounded-2xl bg-[] h-[22px] w-[77px] text-[12px] text-[#1EDF65] text-center">Susscess</button></td>
                                    
                                </tr>;
                            }):null}
                
                
                            
                
                
                    
                    
                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
            </div>
    </div>
      
    </div>
  )
}

export default CryptoPaymentTable
