import React, { useState, useEffect } from 'react'
import { useWalletContext } from 'hooks/walletContext';
import Button from 'shared/Button/Button'
import { ethers } from "ethers";
import { convertToWei } from 'utils/helpers';
import { alertSuccess, alertError } from 'utils/alerts';
import Swal from 'sweetalert2';
import info from "images/info.svg"
import Tooltip from 'components/tooltip';

const TOOLTIPTEXT = `This Feature Enable to Add Token \n to Contract on Holder's behalf`



const ManualBuy = ({ isOwner = false }) => {

    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    const [manObj, setmanObj] = useState({
        whereDidHeBuy: "",
        amount: "",
        holder: ""
    });
    const [loading, setloading] = useState(false)
    const [isDecimal, setisDecimal] = useState(false)
    const manualPay = async () => {

        let obj = manObj
        if (!obj.whereDidHeBuy || !obj.holder || obj.amount <= 0) {
            alertError({ msg: "Please Add valid Values" });
            return
        }

        try {
            setloading(true)
            let weiTokens = isDecimal ? obj.amount : convertToWei(Number(obj.amount))
            const tb = await getContractInstance()

            const amountIn = ethers.utils.parseUnits(weiTokens.toString(), "wei")

            const manualPay = await tb.manualBuy(isDecimal, obj.whereDidHeBuy, obj.holder, amountIn);
            await manualPay.wait()
            // console.log("manualPay ::", manualPay);
            alertSuccess({ msg: `Amount Added to ${obj.holder}` })
            setmanObj({
                whereDidHeBuy: "",
                amount: "",
                holder: ""
            })
            setisDecimal(false)
        } catch (error) {
            console.log("manualPay error", error.message);
            let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
            alertError({ msg });
        } finally {
            setloading(false);
            setisDecimal(false);
        }
    }

    const confirmManualBuy = (e) => {
        let obj = manObj
        if (!obj.whereDidHeBuy || !obj.holder || obj.amount <= 0) {
            alertError({ msg: "Please Add valid Values" });
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to transfer ${manObj.amount} USD to Wallet Address ${manObj.holder}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                manualPay()
            }
          })
    }

    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])


    return (
        <>
            <div className="rounded-lg px-5 py-4 bg-website-220 h-full ">

                <div className='mb-4'>
                    <div className='text-xl font-medium whitespace-nowrap'>Manual Buy <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px]' src={info} /></span></Tooltip></div>
                </div>
                <div className='flex'>
                    <div className='sm:w-[13.7rem]'></div>
                    <div>
                        <div className='flex  gap-3 mb-4'>
                            <div className="h-10 flex place-items-center"><input type="checkbox" disabled={false} isChecked={isDecimal} onClick={(e) => setisDecimal(e.target.checked)} className="rounded-md h-6 w-6 bg-website-500" /></div>
                            <div className="h-10 flex place-items-center"><label className="font-medium">Amount in (18 Decimals)</label></div>
                        </div>
                    </div>
                </div>
                <div className="flex  gap-5">
                    <div className="flex flex-col w-full">
                        <div className="h-12 flex flex-col sm:flex-row sm:justify-center sm:items-center"><label className="font-medium sm:w-1/4 sm:flex sm:justify-end">Where Did He Buy <span className='px-3'>{":"}</span></label>
                            <div className="h-10 flex justify-center items-center w-full sm:pl-2"><input type="text" className='text-sm rounded-md bg-website-500  w-full' placeholder="Where Did He Buy" value={manObj.whereDidHeBuy} onChange={(e) => setmanObj({ ...manObj, whereDidHeBuy: e.target.value })} /></div>
                        </div>
                        <div className="h-12 my-5 flex flex-col sm:flex-row sm:justify-center sm:items-center w-full"><label className="font-medium sm:w-1/4 sm:flex sm:justify-end"> Holder Wallet Address<span className='px-3'>{":"}</span></label>
                            <div className="h-10 flex place-items-center w-full sm:pl-2"><input type="text" className='text-sm rounded-md bg-website-500  w-full' placeholder="Holder Wallet Address" value={manObj.holder} onChange={(e) => setmanObj({ ...manObj, holder: e.target.value })} />
                            </div>
                        </div>
                        <div className="h-12 my-5 mt-2 flex flex-col sm:flex-row sm:justify-center sm:items-center w-full"><label className="font-medium sm:w-1/4 sm:flex sm:justify-end">Amount (in USD)<span className='px-3'>{":"}</span></label>
                            <div className="h-10 flex w-full place-items-center sm:pl-2"><input type="number" className='text-sm rounded-md bg-website-500  w-full' placeholder="Amount in USD" value={manObj.amount} onChange={(e) => setmanObj({ ...manObj, amount: e.target.value })} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-5 ">
                    <Button loading={loading} onClick={() => !isManualBuyDisable ? alertError({ msg: "Please connect with Owner's Wallet" }) : confirmManualBuy()} border="rounded-[7px]" sizeclassName="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem] `}>Manual Buy</Button>
                </div>
            </div>
        </>
    )
}

export default ManualBuy;