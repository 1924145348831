import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";

import Footer from "shared/Footer/Footer";
import SiteHeader from "containers/SiteHeader";

// import PageHome2 from "containers/PageHome/PageHome2";

// import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";

import Page404 from "containers/Page404/Page404";

import PageDashboard from "containers/Dashboard/PageDashboard";

import PageSignUp from "containers/PageSignup/PageSignup";
import PageFiatPayment from "containers/Dashboard/pageFiatPayment";
import PageCryptoPayment from "containers/Dashboard/pageCryptoPayment";
import PageTransaction from "containers/Dashboard/pageTransactions";
import Sidebar from 'components/Sidebar/Sidebar'

import { useSelector } from "react-redux";
import Contribution from "containers/Contribution/Contribution";
import { useWalletContext } from "hooks/walletContext";
import { useAccount } from "wagmi";

export const pages = [
  { path: "/", exact: true, component: PageLogin },
  // { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/dashboard/fiat-payment", component: PageFiatPayment },
  { path: "/dashboard/crypto-payment", component: PageCryptoPayment },
  { path: "/dashboard/transaction", component: PageTransaction },
  { path: "/dashboard/contribution", component: Contribution },

  { path: "/dashboard", component: PageDashboard },
];

const Routes = () => {
  const { address, connector, isConnected } = useAccount();
  let location = useLocation();
  const history = useHistory()
  const isDashboardRoute = location.pathname.toLowerCase().includes('dashboard')
  // console.log("from start",location,isDashboardRoute)
  const loginSignUpRoute = location.pathname.toLowerCase().includes('login') || location.pathname.toLowerCase().includes('signup')
  
  if (isDashboardRoute) {

    return (
      <div className="flex flex-row min-h-screen w-[100vw] p-1">
        <ScrollToTop />
        <Sidebar />
        {/* <SiteHeader /> */}
        <div className="flex-1 w-full">
          <Switch>
            {pages.map(({ component, path, exact }) => {
              if (!isConnected) return <Redirect to="/" />
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <Route component={Page404} />
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {!loginSignUpRoute ? <SiteHeader /> : null}
        <main>
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <Route component={Page404} />
          </Switch>
        </main>
        {!loginSignUpRoute ? <Footer /> : null}
      </>)
  }

};

const DashboardLayout = () => {
  return <div className="flex flex-row">
    <Sidebar />
    <div className="flex-1">
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </div>
  </div>
}

const WebsiteLayout = () => {
  return (
    <>
      <SiteHeader />
      <main>
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
      </main>
      <Footer />
    </>
  )
}
export default Routes;
