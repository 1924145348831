import cross from "images/cross.svg";
import metamaskIco from "images/metamask.webp";
import {useConnect, useDisconnect} from 'wagmi'
import { userSlice } from "../../app/user/user";
import { useDispatch } from "react-redux";
import { alertError } from "utils/alerts";
const ConnectWalletIcon = "data:image/svg+xml,%3Csvg height='185' viewBox='0 0 300 185' width='300' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m61.4385429 36.2562612c48.9112241-47.8881663 128.2119871-47.8881663 177.1232091 0l5.886545 5.7634174c2.445561 2.3944081 2.445561 6.2765112 0 8.6709204l-20.136695 19.715503c-1.222781 1.1972051-3.2053 1.1972051-4.428081 0l-8.100584-7.9311479c-34.121692-33.4079817-89.443886-33.4079817-123.5655788 0l-8.6750562 8.4936051c-1.2227816 1.1972041-3.205301 1.1972041-4.4280806 0l-20.1366949-19.7155031c-2.4455612-2.3944092-2.4455612-6.2765122 0-8.6709204zm218.7677961 40.7737449 17.921697 17.546897c2.445549 2.3943969 2.445563 6.2764769.000031 8.6708899l-80.810171 79.121134c-2.445544 2.394426-6.410582 2.394453-8.85616.000062-.00001-.00001-.000022-.000022-.000032-.000032l-57.354143-56.154572c-.61139-.598602-1.60265-.598602-2.21404 0-.000004.000004-.000007.000008-.000011.000011l-57.3529212 56.154531c-2.4455368 2.394432-6.4105755 2.394472-8.8561612.000087-.0000143-.000014-.0000296-.000028-.0000449-.000044l-80.81241943-79.122185c-2.44556021-2.394408-2.44556021-6.2765115 0-8.6709197l17.92172963-17.5468673c2.4455602-2.3944082 6.4105989-2.3944082 8.8561602 0l57.3549775 56.155357c.6113908.598602 1.602649.598602 2.2140398 0 .0000092-.000009.0000174-.000017.0000265-.000024l57.3521031-56.155333c2.445505-2.3944633 6.410544-2.3945531 8.856161-.0002.000034.0000336.000068.0000673.000101.000101l57.354902 56.155432c.61139.598601 1.60265.598601 2.21404 0l57.353975-56.1543249c2.445561-2.3944092 6.410599-2.3944092 8.85616 0z' fill='%233b99fc'/%3E%3C/svg%3E"

const ConnectWalletModal = () => {
    const dispatch = useDispatch()
    const { disconnect } = useDisconnect();

    const { connect, connectors, error: connectError, isLoading, pendingConnector } = useConnect({
        onSuccess(data) {
            updateAccount(data.account)
            
        },
        onError(error) {
          alertError({msg: error.message})
          disconnect()
        }
    })


    const updateAccount = (accountAddress) => {
        dispatch(userSlice.actions.changeCurrentUser({ id: accountAddress }));
    }

    const connectWallet = (connector) => {
        connect({ connector })
    }

    
    return (
        <div className="py-5 px-3 sm:py-10 sm:px-5 connectWalletModalWidth bg-website-220 rounded-md text-white">
            <div className="text-lg sm:text-2xl flex font-medium justify-between mb-3 sm:mb-5">
                {/* <div className="">Connect Wallet</div> */}
            </div>
            <div className="mt-3 sm:mt-5">
                {
                    connectors.map((connector)=>{
                        return <div className="p-3 sm:p-5 flex gap-3 sm:gap-7 bg-website-500 rounded cursor-pointer mt-2" onClick={()=>{
                                    connectWallet(connector)
                                }}>
                                    <div>
                                        <img className="w-6 sm:w-10 h-6 sm:h-10" src={connector.id=="metaMask"?metamaskIco:ConnectWalletIcon} alt="metamaskIco" /></div>
                                    <div className="flex place-items-center"><p className="text-md sm:text-xl font-medium">{connector.name}</p></div>
                                </div>
                    })
                }
                
                {/* <div className="p-3 sm:p-5 flex gap-3 sm:gap-7 bg-website-500 rounded mt-3 sm:mt-5">
                    <div><img className="w-6 sm:w-10 h-6 sm:h-10" src={tronIco} alt="tronIco" /></div>
                    <div className="flex place-items-center"><p className="text-md sm:text-xl font-medium">Tron</p></div>
                </div> */}
                {/* <div className="p-3 sm:p-5 flex gap-3 sm:gap-7 bg-website-500 rounded mt-3 sm:mt-5">
                    <div><img className="w-6 sm:w-10 h-6 sm:h-10" src={tronIco} alt="tronIco" /></div>
                    <div className="flex place-items-center"><p className="text-md sm:text-xl font-medium">Trust</p></div>
                </div> */}
            </div>
        </div>
    );
}

export default ConnectWalletModal;