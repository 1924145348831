import React from "react";

const Checkbox = ({
  subLabel = "",
  labelClass="",
  label = "",
  name,
  className = "",
  defaultChecked,
  onChange,
}) => {
  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-action-primary h-6 w-6  text-black border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:text-black  dark:checked:bg-primary-500 focus:ring-primary-500"
        defaultChecked={defaultChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <label
          htmlFor={name}
          className="ml-2.5 sm:ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span
            className={`text-neutral-900 dark:text-neutral-100 ${!!subLabel ? "-mt-0.5" : ""
              } ${labelClass}`}
          >
            {label}
          </span>
          {subLabel && (
            <p className="mt-0.5 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
