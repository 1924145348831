import DashboardHeader from "components/Header/DashboardHeader";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import ETH_ICON from 'images/icons/5F33E3F751873296-2.png'
import DollarIcon from 'images/icons/us-dollar.png'
import SaleProgressCmp from 'components/SaleProgress/SaleProgressCmp'
import SliderProgressImage from 'images/sliderprogress.png'
import { isNumber, WeiToNumber } from "utils/helpers";
import { menuSlice } from "app/menu/menu";
import iconone from "../../images/icons/1200px-Bitcoin.svg-1.png"
import icontwo from "../../images/icons/5F33E3F751873296-1.png"

import { ethers } from "ethers";

import { BECCONTRACTADDRESS, BECCONTABI } from "_blockchain/contractsAbi";
// import { promiseStage1Await, promiseStage2Await, promiseStage3Await, promiseStage4Await } from "./myBalance";
import TokenStageProgress from "components/TokenStageProgress/TokenStageProgress";
import { getSaleOnGoing } from "utils/blockchainHelper";
import { useContractContext } from "hooks/contractContext";
import { useAccount, useSigner } from 'wagmi'

import ManualBuy from "components/ManualBuy/ManualBuy";
import { useWalletContext } from 'hooks/walletContext';
import SetIsLive from "components/SetIsLive/SetIsLive";
import SetActualSale from "components/SetActualSale/SetActualSale";
import Initialize from "components/Initialize/Initialize";
import AddToWhiteListToken from "components/WhiteListToken/AddToWhiteListToken";
import SetForcedRate from "components/SetForcedRate/SetForcedRate";
import SetLastBuy from "components/SetLastBuy/SetLastBuy";
import ChangeLiquidityAddress from "components/ChangeLiquidityAddress/ChangeLiquidityAddress";
import ChangeMinAndMaxContribute from "components/ChangeMinAndMaxContribute/ChangeMinAndMaxContribute";
import RemoveTokens from "components/RemoveTokens/RemoveTokens";
import TransferOwnership from "components/TransferOwnership/TransferOwnership";


const Page = () => {
    const NUMBER_REGEX = /^-?\d*\.?\d*$/

    const dispatch = useDispatch()
    const { user } = useSelector((state => state))
    const { getContractInstance, getConnectAddress } = useWalletContext();
    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();
    const [priceInUsdt, setpriceInUsdt] = useState(0)


    const [hardcapCheckBal, sethardcapCheckBal] = useState(0)
    const [rate, setRate] = React.useState(47.619047619)
    const [onGoingSale, setonGoingSale] = useState(0);
    const getSaleOnGoing = async () => {
        const tb = await getContractInstance()
        let sale = await tb.saleOngoing();
        console.log("sale ", sale.toString());
        setonGoingSale(sale.toString())
    };

    const convertOtherCoinToBEC = (x) => {
        if (!x) {
            x = 1
        }
        x = x * (rate || 47.619047619)
        return x.toLocaleString("en-US");
    };

    const [contributionAmount, setContributionAmount] = useState(0)

    const [contractOwner, setcontractOwner] = useState("");
    const [currentAddr, setcurrentAddr] = useState("")
    const [isOwner, setisOwner] = useState(false);
    const [totalAmountRaised, settotalAmountRaised] = useState(0)
    const [unSoldtokenInCurrentPhase, setunSoldtokenInCurrentPhase] = useState(0)
    const getContractOwner = async (second) => {
        const curAddr = await getConnectAddress();
        const tb = await getContractInstance();
        const owner = await tb.owner();
        console.log(" curAddr,owner ", curAddr, owner);
        setisOwner(owner.toLocaleLowerCase() === curAddr.toLocaleLowerCase())
        setcontractOwner(owner.toLocaleLowerCase());
        setcurrentAddr(curAddr.toLocaleLowerCase())
    }

    const gethardcapCheck = async () => {
        const tb = await getContractInstance()
        const tp = await tb.hardcapCheck();
        sethardcapCheckBal(toFullStop(tp.toString()));
    };

    const getHowManyTokenAreSold = async (second) => {
        const tb = await getContractInstance()
        const tokenRate = await tb.viewRate();
        const tp = await tb.hardcapCheck();
        var hardcapchk = Number(tp.toString());
        const newRate = parseFloat((+tokenRate.toString()) / 1000);
        // console.log("hardcapchk ",hardcapchk,newRate );
        let totalAmtRaised = (939139 - hardcapchk);
        settotalAmountRaised(toFullStop(totalAmtRaised))

        let tokenLeftToSoldInCurrentPhase = (hardcapchk * newRate)
        setunSoldtokenInCurrentPhase(toFullStop(tokenLeftToSoldInCurrentPhase))
        var usdtPrice = (939139 - hardcapchk) * newRate;
        // console.log("usdtPrice ::::::::>",usdtPrice);
        setpriceInUsdt(toFullStop(Math.abs(Number(usdtPrice).toFixed(6))))

    }


    useEffect(() => {
        if (hardcapCheckBal > 0) {
            getHowManyTokenAreSold()
        }
    }, [hardcapCheckBal])


    useEffect(() => {
        /* Set Active Menu */
        if (isConnected && wagmiSigner) {
            gethardcapCheck()
            getContractOwner();
            getSaleOnGoing()
        }
        dispatch(menuSlice.actions.setSidebarActiveMenu('dashboard'));
    }, [isConnected, wagmiSigner]);

    return (

        <div className="nc-PageHome relative overflow-hidden nc-fill-container sm:p-4 p-1 w-full">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="Dashboard" />

            <div className="  mt-4 flex flex-col  sm:flex sm:flex-col grid w-full">
                <div className="  bg-primary-900 rounded-lg px-5 py-4 flex flex-col  justify-between ">
                    <div className="text-website-500  sm:flex sm:flex-row sm:place-items-center whitespace-nowrap">
                        <div>
                            Contract Owner :
                        </div>
                        <div className="text-[0.60rem] sm:text-sm">{contractOwner}</div>
                    </div>
                    <div className=" block text-website-500 text-xl font-medium">Hard Cap Check : $ {hardcapCheckBal}</div>
                    <div className=" block text-website-500 text-xl font-medium">
                        Token Purchased (from users) : {priceInUsdt}
                    </div>
                    <div className="block text-website-500 text-xl font-medium">
                        Total Amount Raised : $ {totalAmountRaised}
                    </div>
                    <div className="block text-website-500 text-xl font-medium">
                        Unsold Token In Phase {onGoingSale} : {unSoldtokenInCurrentPhase}
                    </div>
                </div>

                <div className="flex flex-col gap-5 my-5 ">
                    <div className=" w-full h-auto"><SetIsLive isOwner={isOwner} /></div>
                </div>

                <div className="flex flex-col gap-5 mb-5 ">
                    <div className=" w-full h-auto"><SetActualSale isOwner={isOwner} /></div>
                </div>
                <div className="flex gap-5 mb-5 ">
                    <div className=" w-full h-auto"><AddToWhiteListToken isOwner={isOwner} /></div>
                </div>
                <div className="mb-5">
                    <ChangeLiquidityAddress isOwner={isOwner} />
                </div>
                <div className="mb-5">
                    <ChangeMinAndMaxContribute isOwner={isOwner} />
                </div>
                <div className="mb-5">
                    <RemoveTokens isOwner={isOwner} />
                </div>
                <div className="mb-5">
                    <SetForcedRate isOwner={isOwner} />
                </div>
                <div className="mb-5">
                    <SetLastBuy isOwner={isOwner} />
                </div>
                <div className="mb-5 w-full h-auto"><ManualBuy isOwner={isOwner} /></div>
                <div className="flex flex-col gap-5 mb-5 ">
                    <div className=" w-full h-auto"><Initialize isOwner={isOwner} /></div>
                </div>
                <div className="mb-5">
                    <TransferOwnership isOwner={isOwner} />
                </div>


                {/* <div className="  rounded-lg px-5 py-4 bg-website-220 flex flex-col sm:flex sm:flex-row lg:gap-[182px] justify-center items-center">
                    <div className="">
                        <div className="flex justify-center">
                            <div className="flex gap-[6px]">
                                <div><img src={ethereumIcon} /></div>
                                <div>ETH</div>
                            </div>
                        </div>
                        <div className="pt-4">120,000,000.00</div>
                    </div>
                    <div className="">
                        <div className="flex justify-center">
                            <div className="flex gap-[6px]">
                                <div><img src={bitCoinIcon} /></div>
                                <div>BTC</div>
                            </div>
                        </div>
                        <div className="pt-4">65,250.00</div>
                    </div>
                    <div className="">
                        <div className="flex justify-center">
                            <div className="flex gap-[6px]">
                                <div><img src={polygonIcon} /></div>
                                <div>Matic</div>
                            </div>
                        </div>
                        <div className="pt-4">95,452,500.00</div>
                    </div>
                    <div className="">
                        <div className="flex justify-center">
                            <div className="flex gap-[6px]">
                                <div><img src={usdIcon} /></div>
                                <div>USD</div>
                            </div>
                        </div>
                        <div className="pt-4">65,495,685,260.00</div>
                    </div>
                  </div> */}
            </div>

            {/* <div className="flex flex-col lg:flex-row mt-4 gap-5">
                <div className="  bg-website-220 p-5 rounded-md lg:w-[350px]">
                    <div className="text-base">Token Price</div>

                    <div className="flex pt-4 gap-2">
                        <div className="bg-[#050505] p-4 rounded-md">
                            <div className="flex justify-center">
                                <div className="text-2xl">1 Eth</div>
                            </div>
                            <div className="flex justify-center">
                                <div>=</div>
                            </div>
                            <div className="flex justify-center pb-[16px]">
                                <div className="text-2xl text-primary-900">58,476.1904 BEC</div>
                            </div>
                        </div>
                    </div>

                   </div>

                <div className=" bg-website-220 p-5 rounded-md w-full ">
                    <div className="text-base">Token Sales Progress</div>
                    <div className="text-xs text-[#949494] mt-1">You can see here all sales graph about our Token</div>

                    <div className="px-2 py-4 justify-between mt-6">
                        <TokenStageProgress />
                        
                    </div>
                </div>
            </div>
            <div>
                <PageTransaction />
            </div> */}
        </div>
    );
}

export default Page;

function toFullStop(value) {
    if (value === 0) return 0
    value = value.toString()
    let lstval = value.split(".");
    let v = lstval[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return lstval.length === 1 ? v : `${v},${lstval[1]}`
}