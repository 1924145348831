import { useWalletContext } from 'hooks/walletContext'
import React, { useState, useEffect } from 'react'
import Button from 'shared/Button/Button'
import swal from 'sweetalert'
import { alertError, alertSuccess } from 'utils/alerts'
import info from "images/info.svg"
import Tooltip from 'components/tooltip'
const TOOLTIPTEXT = `This Transfers Ownership of Contract`
const TransferOwnership = ({ isOwner = false }) => {
    const [newOwnerAddress, setNewOwnerAddress] = useState(null)
    const { getContractInstance, getConnectAddress } = useWalletContext()


    const transferOwnership = async () => {

        try {
            const tb = await getContractInstance();
            const transferOwnershipFunc = await tb.transferOwnership(newOwnerAddress);
            const result = await transferOwnershipFunc.wait();
            newOwnerAddress("")
            setTimeout(()=>{
                window.location.reload()
              }, 400)
        } catch (err) {
            alertError({ msg: "Invalid Address" });
        }

    }
    const confirmTransferOwnership = (e) => {
        if (!newOwnerAddress) {
            alertError({ msg: "Please Add Valid Owner Address" })
            return
        }
        swal({
            title: 'Are you sure ?',
            text: `You Are About to Transfer Ownership of Contract to ${newOwnerAddress} `,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                transferOwnership()
            }
          });
    }


    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)

    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])

    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
            <div className='text-xl font-medium whitespace-nowrap '>Transfer Ownership <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>
            <div className='text-xl font-medium '> </div>
            <div className="  mt-5 sm:flex place-items-center gap-3 ">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4 ">New Owner Address <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full">
                    <input type="text" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='New Owner Address'
                        value={newOwnerAddress}
                        onChange={(e) => setNewOwnerAddress(e.target.value)}
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button onClick={() => !isManualBuyDisable ? alertError({ msg: "Please connect with Owner's Wallet" }) : confirmTransferOwnership()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Transfer</Button>
            </div>
        </div>
    )
}

export default TransferOwnership