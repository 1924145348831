import Tooltip from 'components/tooltip';
import { useWalletContext } from 'hooks/walletContext'
import React, { useState,useEffect } from 'react'
import Button from 'shared/Button/Button'
import { alertSuccess, alertError } from 'utils/alerts';
import info from "images/info.svg"
import { useAccount, useSigner } from 'wagmi'

const TOOLTIPTEXT = `This will update the ongoing sale`
const SetActualSale = ({isOwner=false}) => {
    const { getContractInstance,getConnectAddress}= useWalletContext()
    const [saleId, setSaleId] = useState(null)
    const[saleIdError,setSaleIdError]=useState()
    const [loading, setloading] = useState(false)
    const [onGoingSale, setonGoingSale] = useState(0);
    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();
    const getSaleOnGoing = async () =>  {
        const tb = await getContractInstance()
        let sale = await tb.saleOngoing();
        console.log("sale ",sale.toString());
        setonGoingSale(sale.toString())
    };
    const verifySaleId = async()=>{
        setSaleIdError("");
        if(saleId == null){
            setSaleIdError("Please Give Sale ID.")
            return
        }
        else if(![1, 2, 3,4].includes(Number(saleId))){
            setSaleIdError("Please Give Valid Sale ID.");
            return
        }
        try {
            setloading(true)
            const tb = await getContractInstance()
            const setSaleId = await tb.setActualSale(Number(saleId));
            await setSaleId.wait()
            console.log("manualPay ::", setSaleId);
            getSaleOnGoing()
            alertSuccess({msg: "Sale Updated on Contract"})
        } catch (error) {
            console.log("update sale error",error);
            let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
            alertError({ msg });
        } finally {
            setloading(false)
        }

    }

    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        if (isConnected && wagmiSigner) { 
            getSaleOnGoing()
        }
        setisManualBuyDisable(isOwner)
    }, [isOwner,isConnected, wagmiSigner])

    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
        <div className='text-xl font-medium whitespace-nowrap '>Set Actual Sale<Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>

                <div className="text-[12px] font-medium whitespace-nowrap">Current Sale : {onGoingSale} </div>
            <div className=" gap-3 mt-5 sm:flex whitespace-nowrap place-items-center">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">Sale Id <span className='px-3'>{":"}</span> </div>
              
                <div className="h-10 w-full ">
                    <select name="actualSell" id="actualSell"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Sale ID'
                        value={saleId}
                        onChange={(e) => setSaleId(e.target.value)}
                    >
                        <option disa>Change Sale Id</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold">{saleIdError}</span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button loading={loading} onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : verifySaleId()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Update Sale </Button>
            </div>
        </div>
    )
}

export default SetActualSale