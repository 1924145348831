import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/logooflogin.png";
import cover from "../../images/coverlogintwo.png"

import Checkbox from "shared/Checkbox/Checkbox";
// import { useTranslation } from "react-i18next";
import { apiGET, apiPOST } from "utils/apiHelper";
import { useDispatch } from "react-redux";
import { userSlice } from "../../app/user/user";

export const REGISTER_URL = "/v1/auth/register";




const PageSignUp = ({ className = "" }) => {
  // const { t } = useTranslation();

  const [name, setName] = useState()//FULL NAME
  const [lastName, setLastName] = useState("")//FULL NAME
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")//confirm  password
  const [termsChecked, setTermsChecked] = useState(false)//terms & conditions
  const [error, setError] = useState("")

  const [rememberTypeStates, setrememberTypeStates] = useState([]);
  const handleChangeSaleType = (checked, name) => {
    checked
      ? setrememberTypeStates([...rememberTypeStates, name])
      : setrememberTypeStates(rememberTypeStates.filter((i) => i !== name));
  };

  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [showCPass, setShowCPass] = useState(false)
  // async function register(email, fullname, lastName, username, password, cpassword, role = "account") {
  //   console.log("register ::", { email, fullname, lastName, username, password, cpassword, role });
  //   setLoading(true)
  //   const fName = fullname;

  //   // console.log(await apiGET("/v1/nftuser"))
  //   // console.log(await apiPOST(REGISTER_URL, {email: "mk@g.in",name:"test1" ,username:"test" ,password :"",role:"user"  }))
  //   let response = await apiPOST('/v1/auth/register', { email, fName, lName: lastName, username, password, role: "user" })
  //   console.log("register ::", response)
  //   if (response.status === 201) {
  //     const { user, tokens } = response.data
  //     dispatch(userSlice.actions.changeCurrentUser({ ...user, tokens }))
  //     // clearError()
  //     history.replace('/')
  //   } else if (response.status === 400) {
  //     // console.log("my bad",response.status)
  //     setError(response.data.data)
  //   }
  //   setLoading(false)
  // }
  const [submitClicked, setSubmitClicked] = useState(false)
  const checkError = () => {
    if (!name) {
      return
    }
    else if (password !== cpassword) {
      return
    } else if (password.length < 8) {
      return
    }
    if (termsChecked !== true) {
      return;
    }
  }

  return (
    <div className={`nc-PageLogin  nc-fill-container w-full bg-cover min-h-screen bg-no-repeat py-28 h-fit  ${className}`} data-nc-id="PageLogin"
    style={{ backgroundImage: `url(${cover})`, backgroundPosition: "center"  }} >

        <div className="bg-[#161616] h-[1050px] w-[80%] md:w-[491px] m-auto py-20 " style={{borderRadius:"6px"}} >
                    <div className="w-[80%] md:w-[331px] flex flex-col items-center h-[570px] m-auto " >
                            <div className="mb-5"><img width="120px" height="120px" src={logo} /></div>
                            <div className="mb-4">  <h1 className="text-[#E5BF00] text-4xl font-strasua">BEC TOKEN</h1></div>
                            <div className="h-[228px] w-full">
                                        
                                  

                                    
                                        <h2 className="py-3 text-center   text-3xl leading-[115%] md:text-4xl font-normal text-neutral-900 dark:text-neutral-100 ">
                                            {'Sign up'}
                                        </h2>
                                        <p className="text-base text-center mb-5" >{'Create New BEC Token Account'}</p>
                                        
        
            {/* FORM */}
            <form className=" grid grid-cols-1 gap-6" onSubmit={(e) => {
              e.preventDefault();
              checkError()
              setSubmitClicked(true)
              // register(email, name, lastName, username, password, cpassword)
            }}>

              <label className="block">
                <span className="text-xs dark:text-neutral-200 bg-[#161616]  p-2 relative top-[0.8rem] left-3">
                  {'Name'} <span className="text-red-600">*</span>
                  {/* <span className="text-red-700">
                        {' *'}
                      </span> */}
                </span>
                <Input
                  required
                  /*   placeholder={'Name'} */
                  className="mt-1 w-full md:w-[330px] rounded-[0.25rem] !bg-[#161616]   dark:bg-[#161616]"
                  onChange={(e) => { setName(e.target.value) }}

                  style={{ border: '1px solid #FFFFFF1C' }}
                />
                {!name && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Name is required"}</span> : null}
              </label>


              <label className="block">
                <span className="text-xs p-2 dark:text-neutral-200 bg-[#161616]  relative top-[0.7rem] left-3">
                  {'Email address'} <span className="text-red-600">*</span>
                  {/* <span className="text-red-700">
                        {' *'}
                      </span> */}
                </span>
                <Input
                  type="email"
                  required
                  /* placeholder="example@example.com" */
                  className="mt-1 w-full md:w-[330px] rounded-[0.25rem] !bg-[#161616] dark:bg-[#161616]"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ border: '1px solid #FFFFFF1C' }}

                />
                {!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Invalid Email"}</span> : null}

              </label>


              <label className="block relative">
                <span className="bg-[#161616]   p-2 relative top-[0.8rem] left-3 text-xs dark:text-neutral-200">
                  {'Password'} <span className="text-red-600">*</span>
                  {/* <span className="text-red-700">
                        {' *'}
                      </span> */}

                </span>
                <div className="flex justify-between w-full  ">
                  <Input required /* placeholder="********" */ type={showPass ? "text" : "password"} className="mt-1 w-full md:w-[330px] rounded-[0.25rem] !bg-[#161616] dark:bg-[#161616]" onChange={(e) => {
                    setPassword(e.target.value);

                  }} style={{ border: '1px solid #FFFFFF1C' }} />

                </div>
                {password.length < 8 && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Password can't be less than 8 characters"}</span> : null}

                <div className="flex  cursor-pointer absolute bottom-8 mx-7 top-[2.5rem]  right-[0]">
                  {/* show password */}
                  {showPass ? <div onClick={() => setShowPass(false)}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                  </svg></div> : <div onClick={() => setShowPass(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                  </div>}
                  {/* hide password */}
                </div>

              </label>
              <label className="block relative">
                <span className="bg-[#161616]  p-2 relative top-[0.8rem] left-3 text-xs dark:text-neutral-200">
                  {'Confirm Password'} <span className="text-red-600">*</span>


                </span>
                <div className="flex justify-between w-full ">
                  <Input required /* placeholder="********" */ type={showCPass ? "text" : "password"} className="dark:bg-[#161616] w-full md:w-[330px] mt-1 rounded-[0.25rem] !bg-[#161616]" onChange={(e) => {
                    setCPassword(e.target.value);
                  }} style={{ border: '1px solid #FFFFFF1C' }} />

                </div>
                {(password !== cpassword) && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Confirm Password Mismatch!"}</span> : null}
                <div className="flex  cursor-pointer absolute mx-7 top-[2.5rem]  right-[0]">
                  {/* show password */}
                  {showCPass ? <div onClick={() => setShowCPass(false)}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                  </svg></div> : <div onClick={() => setShowCPass(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                  </div>}
                  {/* hide password */}
                </div>
              </label>
              <div className="flex justify-between flex-col lg:flex-row lg:items-center">

                <Checkbox className="my-0 "
                  labelClass="text-xs"
                  name={"I Agree To BEC Token Privacy Policy & Terms."}
                  label={"I Agree To BEC Token Privacy Policy & Terms."}
                  defaultChecked={rememberTypeStates.includes("I Agree To BEC Token Privacy Policy & Terms.")}
                  onChange={(checked) =>
                  //  console.log("clikced")
                  {
                    setTermsChecked((e) => !e);

                    handleChangeSaleType(checked, "I Agree To BEC Token Privacy Policy & Terms.")
                  }
                  }

                />
              </div>
              {termsChecked !== true && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Please accept the T&C"}</span> : null}

              <span className="block py-10 text-center text-sm text-neutral-700 dark:text-neutral-300 ">
                <ButtonPrimary
                  className=" w-full  rounded-full h-[36px] text-xs" type="submit" disabled={loading}>{'Sign up'}</ButtonPrimary>
              </span>
            </form>

            {/* ==== */}
                                 
                                                    
                            </div>
                    </div>

        </div>
 


 </div>

  );
};

export default PageSignUp;
