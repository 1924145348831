import React, {useEffect, useState} from 'react'
import { getSaleOnGoing } from 'utils/blockchainHelper'
import second from './range-input.scss'
export default function TokenStageProgress() {

    const [round, setRound] = useState(2)
    const [rangeVal, setRangeVal] = useState(35)

    // const getOngoingSale = async () => {
    //     try {
            // let round = await getSaleOnGoing()
    //         setRound(round)
    //         console.log("getOngoingSale ::", round);
    //         if(round == 1) setRangeVal(4)
    //         if(round == 2) setRangeVal(35)
    //         if(round == 3) setRangeVal(67)
    //         if(round == 4) setRangeVal(100)



    //     } catch (error) {
    //         console.log("getOngoingSale :: error :", error);
    //     }
    // }

    // useEffect(() => {
    //     getOngoingSale()
    // }, [])

  return (
    <div className="w-full custom-range-slider">
      <div className="flex justify-between place-items-center mb-4">
        <div>
          <Round title="Private Sale Round 1" active={round == 1} />
          <div className='flex justify-center pt-[15px]'>
        <div className={`${round==1 ? "bg-primary-900":"bg-website-500"} ${round==2?"bg-primary-900":""} ${round==3?"bg-primary-900":""} ${round==4 ? "bg-primary-900":"bg-website-500"} rounded-full w-3 h-3`}></div>
       </div>
        </div>
        <div>
        <Round title="Private Sale Round 2" active={round == 2} />
        <div className='flex justify-center pt-[15px]'>
        <div className={` ${round==2 ? "bg-primary-900":"bg-website-500"} ${round==3 ? "bg-primary-900":"bg-website-500"} ${round==4 ? "bg-primary-900":"bg-website-500"} rounded-full w-3 h-3`}></div>
       </div>
        </div>
        <div className='pt-2'>
        <Round title="Presale" active={round == 3} />
        <div className='flex justify-center pt-[25px]'>
        <div className={`${round==3 ? "bg-primary-900":"bg-website-500"} ${round==4 ? "bg-primary-900":"bg-website-500"} rounded-full w-3 h-3`}></div>
       </div>
        </div>
       <div className='pt-2'>
       <Round title="Public sale" active={round == 4} />
       <div className='flex justify-center pt-[25px]'>
        <div className={`${round==4 ? "bg-primary-900":"bg-website-500"} rounded-full w-3 h-3`}></div>
       </div>
       </div>
        
      </div>
      {/* <input disabled value={rangeVal} className="w-full px-2 h-2.5 bg-website-220 mt-5 rounded-2xl"  type="range" min="0" max="100"/> */}
      <div className="w-full bg-website-500 h-2 rounded-lg">
        <div
          style={{  width: rangeVal + "%" }}
          className="bg-primary-900 h-2 relative rounded-lg"
        >
          <div className="rounded-full w-4 h-4 absolute bg-primary-900 right-[-9px] top-[50%] translate-y-[-50%]"></div>
          <div className="rounded-full w-5 h-5 absolute border border-primary-900 right-[-11px] top-[50%] translate-y-[-50%]"></div>
        </div>
      </div>
    </div>
  );
}

const Round = ({title, active=false}) => {
    if(active){
        return <div className="text-sm bg-website-500 p-3.5">
            <p className="max-w-[5.375rem] font-medium text-center text-primary-900">{title}</p>
        </div>
    }
    return  <div className="text-sm">
            <p className="max-w-[5.375rem] font-medium text-center">{title}</p>
        </div>
}