import React, { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useSigner,useAccount  } from 'wagmi';
import { BECCONTRACTADDRESS, BECCONTABI,USDTCONTRACTADDRESS,USDTABI,USDCCONTRACTADDRESS,USDCABI,BUSDCONTRACTADDRESS,BUSDABI } from "_blockchain/contractsAbi";

const WalletContext = createContext();
export const useWalletContext = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [contextData, setContextData] = useState({ contractInstance: null });

  const { address , isConnected } = useAccount();
  const {data: wagmiSigner} = useSigner();

  const getContractInstance = async () => {
   if(!isConnected && !wagmiSigner) return null
    const tb = new ethers.Contract(BECCONTRACTADDRESS, BECCONTABI, wagmiSigner);
    setContextData({...contextData,contractInstance:tb})
    return tb
  }

  const getUSDTContractInstance = async () => {
    if(!isConnected && !wagmiSigner) return null
    const tb = new ethers.Contract(USDTCONTRACTADDRESS, USDTABI, wagmiSigner);
    return tb
  }

  const getUSDCContractInstance = async () => {
    if(!isConnected && !wagmiSigner) return null
    const tb = new ethers.Contract(USDCCONTRACTADDRESS, USDCABI, wagmiSigner);
    return tb
  }

  const getBUSDContractInstance = async () => {
    if(!isConnected && !wagmiSigner) return null
    const tb = new ethers.Contract(BUSDCONTRACTADDRESS, BUSDABI, wagmiSigner);
    return tb
  }

  const getConnectAddress = async(second) => { 
    if(!isConnected) return null
    return  address
   }

   return (
    <WalletContext.Provider value={{ getContractInstance, getConnectAddress,getUSDCContractInstance,getUSDTContractInstance,getBUSDContractInstance}}>
      {children}
    </WalletContext.Provider>
  );
};
