import { useWalletContext } from 'hooks/walletContext'
import React, { useState,useEffect } from 'react'
import Button from 'shared/Button/Button'
import { alertError, alertSuccess } from 'utils/alerts'
import info from "images/info.svg"
import Swal from 'sweetalert2';

import Tooltip from 'components/tooltip'
const TOOLTIPTEXT = `Last Reward Enable/Disable for User in Contract`
const SetLastBuy = ({isOwner=false}) => {
    const [useYesNo, setUseYesNo] = useState(null)
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [loading, setloading] = useState(false)

    const setLastBuy = async () => {
        setloading(true)
        try {
            if(useYesNo === null){
                alertError({msg:"Please select Last buy Value"})
                return
            }
            const tb = await getContractInstance();
            const LastBuyFunc = await tb.setLastBuy(useYesNo);
            const result = await LastBuyFunc.wait();
            alertSuccess({msg: "LastBuy changed Successfully"});
            setUseYesNo(null)
        } catch (err) {
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Unauthorized Access"
            alertError({ msg });
        }finally{
            setloading(false)
        }
    }

    const confirmLastBy = (e) => {
        if(useYesNo === null){
            alertError({msg:"Please select Last buy Value"})
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to change Rate in contract`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                setLastBuy()
            }
          })
    }
    
    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])
    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
                   <div className='text-xl font-medium whitespace-nowrap '>Last Buy <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>

            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">Set Last Buy <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                <select name="UseYesNo" id="UseYesNo"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Change UseYesNo'
                        value={useYesNo}
                        onChange={(e) => setUseYesNo(e.target.value)}
                    >
                        <option disa>none</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button loading={loading} onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : confirmLastBy()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem] `}>Update Last Buy </Button>
            </div>
        </div>
    )
}

export default SetLastBuy