import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    sidebarActiveMenuId: ""
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
        setSidebarActiveMenu: (
        state,
        action
        ) => {
            return {
                ...state,
                sidebarActiveMenuId: action.payload,
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setSidebarActiveMenu
} = menuSlice.actions;

export const selectCurrentMenu = (state) => state.menu;

export default menuSlice.reducer;
