import { useWalletContext } from 'hooks/walletContext'
import React, { useState ,useEffect} from 'react'
import Button from 'shared/Button/Button'
import { alertError, alertSuccess } from 'utils/alerts'
import info from "images/info.svg"
import Tooltip from 'components/tooltip'
import Swal from 'sweetalert2';

const TOOLTIPTEXT = `This changes current rate on contract`
const SetForcedRate = ({isOwner=false}) => {
    const [useBool, setUseBool] = useState(null)
    const [rate, setRate] = useState()
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const setForcedRate = async () => {
        try {
            if(!rate){
                alertError({msg:"Please add rate "})
                return
            }
            if(useBool === null){
                alertError({msg:"Please select force value"})
                return
            }
            const tb = await getContractInstance();
            const ForcedRateFunc = await tb.setForcedRate(useBool, rate);
            const result = await ForcedRateFunc.wait();
            // console.log("newLiquidity ::", newLiquidity);
            console.log("result ::", result);
            alertSuccess({msg: "Forced Rate changed Successfully"});
            setUseBool(null)
            setRate(null)
        } catch (err) {
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Unauthorized Access"
            alertError({ msg });
        }
    }

    const confirmForcedRate = (e) => {
        if(!rate){
            alertError({msg:"Please add rate "})
            return
        }
        if(useBool === null){
            alertError({msg:"Please select force value"})
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to change Rate in contract`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                setForcedRate()
            }
          })
    }
    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])
    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
          <div className='text-xl font-medium whitespace-nowrap '>Forced Rate<Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>

            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap ">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">Force <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full">
                    <select name="useBool" id="useBool"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Forced'
                        value={useBool}
                        onChange={(e) => setUseBool(e.target.value)}
                    >
                        <option disa>none</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </div>
            </div>
            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className="sm:flex sm:justify-end font-medium sm:w-1/4">Rate <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full ">
                    <input type="number" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Rate '
                        value={rate}
                        onChange={(e) => { setRate(e.target.value) }}
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : confirmForcedRate()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Update Force Rate </Button>
            </div>
        </div>
    )
}

export default SetForcedRate