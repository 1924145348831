import { BigNumber } from 'ethers'
import { useWalletContext } from 'hooks/walletContext'
import React, { useEffect, useState } from 'react'
import moment from "moment";
import Button from 'shared/Button/Button'
import { alertSuccess, alertError } from 'utils/alerts';
import Swal from 'sweetalert2';
import info from "images/info.svg"
import { useAccount, useSigner } from 'wagmi'

import Tooltip from 'components/tooltip'
const TOOLTIPTEXT = `This Initialize the Token Generation Event on Contract`

const Initialize = ({ isOwner = false }) => {
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [status, setStatus] = useState()//isTGENow bool
    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();
    const [tGEDate, setTGEDate] = useState(null)
    const [saleIdError, setSaleIdError] = useState()
    const [loading, setloading] = useState(false)

    const [alreadySetDate, setAlreadySetDate] = useState(null)
    const findCurrentTGE = async () => {
        const tb = await getContractInstance()
        const value = await tb.whenIsTheTge();
        if (value <= 0) return;
        const myDate = new Date(value * 1000);
        let mDate = moment(myDate).format('MMMM Do YYYY');
        setAlreadySetDate(mDate)
    }

    const verifySaleId = async () => {
        if (!status) {
            setSaleIdError("Please Select Status")
            return
        }
        if (status == "false" && tGEDate == null) {
            setSaleIdError("Please Select TGE Date ")
            return
        }
        
        try {
            setloading(true)
            try {
                let UTCTimeStamp = parseInt(Math.round(new Date(tGEDate).getTime() / 1000).toFixed(0));
                console.log("UTCTimeStamp :", UTCTimeStamp, typeof (UTCTimeStamp))
                const tb = await getContractInstance()
                const initializeRes = await tb.initialize(status === 'true' ? true : false, Number(UTCTimeStamp));
                await initializeRes.wait()
                console.log("initializeRes ::", initializeRes);
                alertSuccess({ msg: "TGE Initialized Successfully." })
            } catch (error) {
                console.log("verifySale error", error);
                let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
                alertError({ msg });
            } finally {
                setloading(false)
            }
        } catch (error) {
            alert("UnAuthorized Access!")
        }

    }


    const confirmInitialize = (e) => {
        if (!status) {
            setSaleIdError("Please Select Status")
            return
        }
        if (status == "false" && tGEDate == null) {
            setSaleIdError("Please Select TGE Date ")
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to Initialize TGE on Contract`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                verifySaleId()
            }
          }).finally(()=>{
            setSaleIdError("")
          })
    }


    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)

    useEffect(() => {
        if (isConnected && wagmiSigner) {findCurrentTGE()}
        setisManualBuyDisable(isOwner)
    }, [isOwner,isConnected, wagmiSigner])
    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
            <div className='sm:flex place-items-center whitespace-nowrap sm:gap-2'>
                <div className='text-xl font-medium '>Initialize TGE</div>
                <div><span className=''>( {alreadySetDate ? alreadySetDate : "Not Set"} ) <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></span></div>
            </div>
            <div className={` gap-3 mt-5 sm:flex place-items-center whitespace-nowrap`}>
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4 ">TGE Now Status <span className='px-3'>{":"}</span> </div>
                <div className="h-10 w-full">
                    <select name="isLive" id="isLive"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='Change Status'
                        value={status}
                        onChange={(e) => {console.log("e.target.value",e.target.value); setStatus(e.target.value)}}
                    >
                        <option disa>Change Status</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </div>
            </div>
            {/* <div className="flex justify-end mt-5 ">
                <Button disabled={isLiveDisable} onClick={changeLiveStatus} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 `}>Save</Button>
            </div> */}
            <div style={{display:`${status == "true"? "none":""}`}} className={` gap-3 mt-2 sm:flex place-items-center whitespace-nowrap`}>
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4">TGE <span className='px-3'>{":"}</span></div>
                <div className="h-10 dateIconColor w-full ">
                    <input type="date"
                        className="text-sm rounded-md text-white bg-website-500  w-full"
                        placeholder='TGE value'
                        value={tGEDate}
                        onChange={(e) => setTGEDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold">{saleIdError}</span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button loading={loading} onClick={() => !isManualBuyDisable ? alertError({ msg: "Please connect with Owner's Wallet" }) : confirmInitialize()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem] `}>Initialize TGE</Button>
            </div>
        </div>
    )
}

export default Initialize