import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { apiPOST } from "utils/apiHelper";
import userReducer, { userSlice } from "../../app/user/user";
import logo from "../../images/logooflogin.png";
import cover from "../../images/coverlogintwo.png"
import { useAccount, useBalance, useConnect, useDisconnect, useEnsAvatar, useEnsName, useNetwork, useSigner, useSwitchNetwork } from 'wagmi'
import Checkbox from "shared/Checkbox/Checkbox";
import { useWalletContext } from "hooks/walletContext";
import { alertError } from "utils/alerts";
import ConnectWalletModal from 'components/ConnectWalletModal/ConnectWalletModal'
import { BECCONTABI, BECCONTRACTADDRESS } from "_blockchain/contractsAbi";

const PageLogin = ({ className = "" }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [loding, setLoding] = useState(false)
    const [error1, setError] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false);
    const [passworderror, setpasswordError] = useState("");
    const [emailerror, setemailError] = useState("");
    const [submitClicked, setSubmitClicked] = useState(false)
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
    const { address, connector, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { data: wagmiSigner } = useSigner();

    async function loginClickHandler() {
        if (!email) {
            setemailError("Email is required");
            return
        }
        if (!password) {
            setpasswordError('Password is required ');
            return
        }
        let payload = {
            email,
            password,
        };
        // try{
        //   setLoading(true);
        //   let response = await apiPOST("/v1/auth/login", payload);
        //   setLoading(false);
        //   if (response.status == "200") {
        //     const { user, tokens } = response.data;
        //     localStorage.setItem("accessToken", tokens.access.token);
        //     dispatch(userSlice.actions.changeCurrentUser({ ...user, tokens }));
        //     clearError();
        //     history.replace("/"); 
        //   } else {
        //     // setError(response?.data?.message);
        //     // if (!email) setemailError("Email is required"); 
        //     setemailError(response?.data?.message);
        //   }
        // }
        // catch(error){
        //   setemailError(error);
        // }

    }

    const handleEmail = (e) => {
        setemailError("");
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setpasswordError("");
        setPassword(e.target.value);
    };
    /* Login */
    const clearError = () => {
        setError('')
    }

    async function loginClickHandler() {
        return history.replace('/dashboard')
        setSubmitClicked(true)
        if (!email) return
        if (password.length < 8) return
        let payload = {
            email,
            password
        }
        history.replace('/dashboard')
        // window.location.push()
        // setLoding(true)
        // let response = await apiPOST('/v1/auth/login', payload)
        // setLoding(false)
        // if (response.status == '200') {
        //     const { user, tokens } = response.data
        //     dispatch(userSlice.actions.changeCurrentUser({ ...user, tokens }))
        //     clearError()
        //     history.replace('/')
        // } else {
        //     setError(response?.data?.message)
        // }
    }
    const { getContractInstance, getConnectAddress } = useWalletContext()

    // const getContractOwner = async () => {
  
    //     if(true){
    //         console.info("owner is here");
    //         window.location.replace("/dashboard")
    //     } else {
    //         alertError({ msg: "You are not the owner of contract!" })
    //     }
    // }

    const getContractOwner = async (curAddr) => {
        try {
            const tb = new ethers.Contract(BECCONTRACTADDRESS, BECCONTABI, wagmiSigner);

            // const tb = await getContractInstance();
            if(!tb) return
            const owner = await tb.owner();
            console.log(" curAddr ",curAddr, owner);
            if(owner?.toLowerCase() === curAddr?.toLowerCase()){
                window.location.replace("/dashboard")
            } else {
                alertError({ msg: "You are not the owner of contract!" })
                disconnect()
            }
        } catch (error) {
            disconnect()
            alertError({ msg: error.message })
        }
    }


    useEffect(() => {
        console.log("***************isConnected************** ::", isConnected, address,wagmiSigner);
        if(isConnected && wagmiSigner ){
            getContractOwner(address)
        }
    }, [isConnected,wagmiSigner])
    

    return (
        <>
            <div className={`nc-PageLogin  nc-fill-container w-full bg-cover min-h-screen bg-no-repeat py-28 h-fit  ${className}`} data-nc-id="PageLogin"
                style={{ backgroundImage: `url(${cover})`, backgroundPosition: "center" }} >

                <div className="bg-[#161616]  w-[80%] md:w-[491px] m-auto py-20 " style={{ borderRadius: "6px" }}>
                    <div className="w-[80%] md:w-[331px] flex flex-col items-center  m-auto " >
                        <div className="mb-5"><img width="120px" height="120px" src={logo} /></div>
                        <div className="mb-4">  <h1 className="text-[#E5BF00] text-4xl font-strasua">BEC TOKEN</h1></div>
                        <div className=" w-full">




                            <h2 className="py-3 text-center   text-3xl leading-[115%] md:text-4xl font-normal text-neutral-900 dark:text-neutral-100 ">
                                {'Login'}
                            </h2>
                            {/* <p className="text-base text-center mb-5" >{'Enter BEC Token login details'}</p> */}

                            <div className="">

                                <div action="#" method="post">
                                    <ConnectWalletModal />
                                </div>

                                {/* ==== */}
                                {/* <span className="block text-center text-neutral-700 dark:text-neutral-300 text-sm">
                                Don't have an account? {` `}
                                <Link className="text-primary-900" to="/signup">
                                    Sign up
                                </Link>
                            </span> */}
                            </div>

                        </div>
                    </div>

                </div>



            </div>

        </>


    );

};

export default PageLogin;
