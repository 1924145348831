import React, { memo } from "react";



const Tooltip= memo(({text,children}) => {
  return (
    <span className="group relative z-50">
      <span className="w-[200px] text-[12px]  z-50 pointer-events-none absolute -top-10 left-1/2  -translate-x-1/2  rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
        <p className="break-after-all" style={{wordBreak:"break-all"}}>{text}</p>
      </span>
      {children}
    </span>
  );
});

// Tooltip.displayName = "Tooltip";

export default Tooltip;
