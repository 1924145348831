import { useWalletContext } from 'hooks/walletContext'
import React, { useState ,useEffect} from 'react'
import Button from 'shared/Button/Button'
import { alertError ,alertSuccess} from 'utils/alerts'
import info from "images/info.svg"
import Tooltip from 'components/tooltip';
import Swal from 'sweetalert2';

const TOOLTIPTEXT = `This changes minimum and maximum value of contribution on contract`
const ChangeMinAndMaxContribute = ({isOwner=false}) => {
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    const { getContractInstance,getConnectAddress}= useWalletContext()
   
    const changeContribute = async () => {
        // console.log("tokenAddress :", newLiquidity)
        if(!min || !max){
            alertError({msg:"Please Add Min and Max value"})
            return;
        }
        try{
            const tb = await getContractInstance();
            const newLiquidityFunc = await tb.changeMinAndMaxContribute(min,max);
            const result=await newLiquidityFunc.wait();
            alertSuccess({msg:"Liquidity address changed Successfully"});
            setMin("")
            setMax("")
        }catch(err){
            let msg = err.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Unauthorized Access"
            alertError({ msg });
        }
    }

    const confirmMinMax = (e) => {
        if(!min || !max){
            alertError({msg:"Please Add Min and Max value"})
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about Change Min and Max Values`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
                changeContribute()
            }
          })
    }


    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)
    
    useEffect(() => {
        setisManualBuyDisable(isOwner)
    }, [isOwner])

    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
              <div className='text-xl font-medium whitespace-nowrap '>Update Minimum & Maximum Contribution<Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px] ml-1' src={info} /></span></Tooltip></div>
            <div className=" gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4"> Min <span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full">
                    <input type="number" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='value'
                        value={min}
                        onChange={(e) =>setMin(e.target.value)} 
                    />
                </div>
            </div>
            <div className="gap-3 mt-5 sm:flex place-items-center whitespace-nowrap">
                <div className=" sm:flex sm:justify-end font-medium sm:w-1/4"> Max<span className='px-3'>{":"}</span></div>
                <div className="h-10 w-full">
                    <input type="number" min="1" max="4"
                        className="text-sm rounded-md bg-website-500  w-full"
                        placeholder='value'
                        value={max}
                        onChange={(e) =>setMax(e.target.value)} 
                    />
                </div>
            </div>
            <div className="text-sm mt-1">
                <span className="text-[#ff0000] font-bold"></span>
            </div>
            <div className="flex justify-center mt-5 ">
                <Button onClick={() => !isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : confirmMinMax()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Update </Button>
            </div>
        </div>
    )
}

export default ChangeMinAndMaxContribute