import ncNanoId from "utils/ncNanoId";
import CollectionIcon from "images/icons/collections.png"
import HomeIcon from "images/icons/home.png"
import MarketplaceIcon from "images/icons/marketplace.png"

export const NAVIGATION_DEMO_2 = [
  {
    id: ncNanoId(),
    href: "#",
    icon: HomeIcon,
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "#elchai-ecosystem",
    icon: CollectionIcon,
    name: "Elchai Ecosystem",
  },
  {
    id: ncNanoId(),
    href: "#token",
    icon: MarketplaceIcon,
    name: "Token",
  },
  {
    id: ncNanoId(),
    href: "#light-paper",
    icon: MarketplaceIcon,
    name: "Light Paper",
  },
  {
    id: ncNanoId(),
    href: "#our-team",
    icon: MarketplaceIcon,
    name: "Our Team",
  },
];

/* 
Home
Elchai Ecosystem
Token
Light Paper
Our Team
Language
Login
*/