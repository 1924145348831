import React from 'react'
import { truncateAddress } from './MainNav2Logged'
import { ethers } from "ethers";
import DropDown from 'shared/NcDropDown/DropDown';
import { getSaleOnGoing } from 'utils/blockchainHelper'
import { userSlice } from "../../app/user/user";
import { useDispatch } from "react-redux";
import {useHistory} from 'react-router-dom'
import { BECCONTRACTADDRESS, BECCONTABI } from "_blockchain/contractsAbi";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Button from 'shared/Button/Button';
import downloadIcon from 'images/icons/downloadIcon.png'
import userIcon from 'images/avatars/user2.png'
import MenuBar from 'shared/MenuBar/MenuBar';
import { useAccount, useDisconnect, useSigner } from 'wagmi';
import { useWalletContext } from 'hooks/walletContext';


export default function DashboardHeader({
  title
}) {

  const [account, setAccount] = React.useState();
  const [error, setError] = React.useState("");
  const dispatch = useDispatch()
  const history = useHistory()
  const { address, connector, isConnected } = useAccount();
  const { getContractInstance, getConnectAddress } = useWalletContext()
  const { data: wagmiSigner } = useSigner();
  const { disconnect } = useDisconnect()

  const [rate, setRate] = React.useState(0)

  const getRates = async () => {
    const tb = await getContractInstance()
    const tokenRate = await tb.viewRate();
    const rate = parseFloat(1 / tokenRate).toFixed(8)
    setRate(rate * 1000)
    console.log("Dashboard Rate ::", rate);
}

  const disconnectWallet = async () => {
    disconnect()
    dispatch(userSlice.actions.removeUser({}))
    setTimeout(function () { history.replace("/"); }, 700);
  };

  React.useEffect(() => {
    if(isConnected && wagmiSigner){
      getRates()
    }
  }, [isConnected, wagmiSigner])

  return (
    <>
    <div className='hidden xl:flex justify-between items-center bg-website-220 px-5 py-5 rounded-lg'>
      <h1 className='text-lg'>{title}</h1>
      <div className='divFlex flex flex-row items-center'>
      <div className='text-sm mr-4'>1 BEC = <span className='text-primary-900'>{Number(Number(rate).toFixed(6))} USD</span></div>
     <div>
      <Button border="rounded-md" className='bg-website-500'>
        <div className='flex gap-1'>
          <div><img src={downloadIcon} /></div>
          <div onClick={()=>window.open("/ELCHAI_ Tokenomics_ENG v.1.pdf", "_blank")} className='text-xs'> Download Light Paper</div>
        </div>
      </Button>
     </div>
       <div className='flex pl-4 gap-2 place-items-center whitespace-nowrap'>
          <div className='text-xs bg-primary-900 text-website-500 px-2 py-2 rounded-lg cursor-pointer' onClick={disconnectWallet}>Logout</div>
          <div className='text-xs'>Admin</div>
          <div className='w-[32px]'><img src={userIcon}/></div>
       </div>
        {/* <div className='mr-4'>
          <ButtonPrimary onClick={() => disconnectWallet()}  fontSize="test-sm font-medium" sizeClass="px-6 py-1">Log Out</ButtonPrimary>
        </div> */}
        {/* <DropDown className='text-neutral-300' data={[{ name: "Disconnect Wallet" }]} account={account} disconnectWallet={disconnectWallet}/> */}
      </div>
    </div>
    <div className='flex justify-between xl:hidden'>
    <div className="logo-head mt-8">
        <h1 className="text-2xl whitespace-nowrap cursor-pointer font-strasua text-primary-900 uppercase text-center">
          BEC Tokens
        </h1>
      </div>
    <MenuBar/>
    </div>
    </>

  )
}
