import { useWalletContext } from 'hooks/walletContext'
import React, { useEffect, useState } from 'react'
import Button from 'shared/Button/Button'
import { alertError, alertSuccess } from 'utils/alerts'
import { useAccount, useSigner } from 'wagmi'
import info from "images/info.svg"
import Tooltip from 'components/tooltip';
const TOOLTIPTEXT = `This will starts the sale live`
const SetIsLive = ({isOwner=false}) => {
    const { getContractInstance } = useWalletContext();
    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();

    const [status, setStatus] = useState()
    const [isLiveDisable, setIsLiveDisable] = useState(true)
    const [loading, setloading] = useState(false)
    const [onGoingSale, setonGoingSale] = useState(0)
    const getSaleOnGoing = async () =>  {
        const tb = await getContractInstance();
        let sale = await tb.saleOngoing();
        console.log("sale ",sale.toString());
        setonGoingSale(sale.toString())
    };

    const changeLiveStatus = async () => {
            try {
                if(onGoingSale > 0){
                    alertError({msg:"Sale is Already Live"})
                    return
                }
                setloading(true)
                const tb = await getContractInstance()
                const liveBtn = await tb.setIsLive();
                await liveBtn.wait()
                alertSuccess({msg:"Sale is Live on Contract"})
                console.log("live done ::", liveBtn);
            } catch (error) {
                console.log("Live status error",error);
                let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
                alertError({ msg });
            } finally {
                setloading(false)
            }
    }
   
    const [isManualBuyDisable, setisManualBuyDisable] = useState(false)

    useEffect(() => {
        if (isConnected && wagmiSigner) {
        getSaleOnGoing()
    }
        setisManualBuyDisable(isOwner)
    }, [isOwner,isConnected, wagmiSigner])
    
    return (
        <div className="rounded-lg px-5 py-4 bg-website-220 h-full">
            <div className='text-xl font-medium whitespace-nowrap'>Set Sale Live  <Tooltip text={TOOLTIPTEXT} ><span className='inline-block' id="manualinfo" ><img className='max-h-[20px] max-w-[20px]' src={info} /></span></Tooltip></div>
            <div className=" gap-5 mt-5">
            </div>
            <div className="flex justify-center mt-5 ">
                <Button loading={loading} onClick={()=>!isManualBuyDisable ? alertError({msg: "Please connect with Owner's Wallet"}) : changeLiveStatus()} border="rounded-[7px]" sizeClass="px-7 py-[9px]" className={`bg-primary-900  text-website-220 w-[15.063rem]`}>Update Status</Button>
            </div>
        </div>
    )
}

export default SetIsLive